import React, { useEffect } from 'react';
import "./MainPage.css";
import { firestore } from "../../firebaseConfig/firebaseConfig";
import { addDoc, collection } from '@firebase/firestore';

function MainPage() {

    async function submitNewUserDetail(event) {
        event.preventDefault();
        let name = document.querySelector(".input_name").value;
        let email = document.querySelector(".input_email").value;
        let tel = document.querySelector(".input_tel").value;
        let company = document.querySelector(".input_company").value;

        await addDoc(collection(firestore, "customer_details_app"), {
            name: name,
            email: email,
            phone: tel,
            company: company
        }).then(() => {
            document.querySelector(".input_name").value = "";
            document.querySelector(".input_email").value = "";
            document.querySelector(".input_tel").value = "";
            document.querySelector(".input_company").value = "";
            document.querySelector(".success-message").style.display = "block";
            setTimeout(() => {
                document.querySelector(".success-message").style.display = "none";
            }, 2000);
        })
    }

    useEffect(() => {
        console.log();
        let height = window.innerHeight;
        document.querySelector(".main-page-content-container").style.height = height+"px";
        document.querySelectorAll(".main-page-content-container .main-page-content-container-inner").forEach((element) => {
            element.style.height = height+"px";
        })
    }, [])

    return (
        <div className='main-page-container'>
            <div className="main-page-nav-container">
                <div className='main-page-nav-logo'>
                    <img src={require("../../images/landing-page-logo.webp")} alt="" />
                </div>
                <div className='main-page-nav-elements'>
                    <a href='#contactUs' className='main-page-nav-element'>
                        Contact Us
                    </a>
                </div>
            </div>
            <div className='main-page-content-container'>
                <div className='main-page-content-container-inner'>
                    <div className='main-page-content-image'>
                        <img src={require("../../images/landing-page-logo.webp")} alt="" />
                    </div>
                    <div className='main-page-content-text'>
                        <div className="main-page-content-text-head">
                            Our strategies for better returns
                        </div>
                        <div className='main-page-content-text-content'>
                            WE AIM TO ESTABLISH HIGH LEVEL INTERACTION WITH CUSTOMERS & SUPPLIERS
                        </div>
                        <a href='#start' className='main-page-content-text-button'>
                            GET STARTED
                        </a>
                    </div>
                </div>
                <div className='main-page-content-container-inner' id='start'>
                    <div className='main-page-content-image'>
                        <img src={require("../../images/main-page-image.webp")} alt="" />
                    </div>
                    <div className='main-page-content-text'>
                        A MODERN AUTOMOBILE CRM that puts customer, <br />
                        inventory, and service management at your fingertips.
                    </div>
                </div>
                <div className='main-page-content-container-inner'>
                    <div className='main-page-content-head'>
                        SECURE & EASY PAYMENT
                    </div>
                    <div className='main-page-content-image'>
                        <img src={require("../../images/services-page-image.webp")} alt="" />
                    </div>
                    <div className='main-page-content-text'>
                        Implementation of advanced security layers to <br /> secure your transactions
                    </div>
                </div>
                <div className='main-page-content-container-inner'>
                    <div className='main-page-content-head'>
                        ENHANCED CUSTOMER EXPERIENCE
                    </div>
                    <div className='main-page-content-element'>
                        <div className='main-page-content-element-part'>
                            <div className='main-page-content-image'>
                                <img src={require("../../images/services-page-image-2-1.webp")} alt="" />
                            </div>
                            <div className='main-page-content-text-part'>
                                Gathers information about the customer's requirements.
                            </div>
                        </div>
                        <div className='main-page-content-element-part'>
                            <span></span>
                        </div>
                        <div className='main-page-content-element-part'>
                            <div className='main-page-content-image'>
                                <img src={require("../../images/services-page-image-2-2.webp")} alt="" />
                            </div>
                            <div className='main-page-content-text-part'>
                                Gathered information goes through a well-defined REFINEMENT process.
                            </div>
                        </div>
                        <div className='main-page-content-element-part'>
                            <span></span>
                        </div>
                        <div className='main-page-content-element-part'>
                            <div className='main-page-content-image'>
                                <img src={require("../../images/services-page-image-2-3.webp")} alt="" />
                            </div>
                            <div className='main-page-content-text-part'>
                                Deliver's optimal user experience by incorporating captured leads.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-page-content-container-inner' id='contactUs'>
                    <form className='contact-us-container' onSubmit={event => submitNewUserDetail(event)}>
                        <div className='form-container-head'>
                            CONTACT <span style={{color: "red"}}>US</span>
                        </div>
                        <div className='contact-us-container-form'>
                            <div className='form-container-inputs'>
                                <input className='input_name' type="text" placeholder='Name' required />
                                <input className='input_email' type="email" placeholder='Email' />
                                <input className='input_tel' type="tel" placeholder='Phone Number' required />
                                <input className='input_company' type="text" placeholder='Company Name' required />
                                <span className='success-message'>The Details have been Submitted.</span>
                            </div>
                            <div className='contact-us-container-image'>
                                <img src={require("../../images/contact-us-image.webp")} alt="" />
                            </div>
                        </div>
                        <div className='form-container-button'>
                            <input type="submit" value="SUBMIT" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MainPage