import "./Footer.css";
import React from 'react';
import { AiFillFacebook, AiFillInstagram, AiFillYoutube, AiFillPhone, AiOutlineMail } from 'react-icons/ai'
import { BsTwitter } from 'react-icons/bs'

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-container-upper-part">
                <div className="footer-upper-left-container">
                    <div className="footer-upper-left-logo">
                        <img src={require("../../images/footer-logo.webp")} alt="" />
                    </div>
                    <div className="footer-upper-left-socials">
                        <a href="/"><AiFillFacebook /></a>
                        <a href="/"><AiFillInstagram /></a>
                        <a href="/"><BsTwitter /></a>
                        <a href="/"><AiFillYoutube /></a>
                    </div>
                </div>
                <div className="footer-upper-right-container">
                    <div className="footer-upper-right-head">
                        GET IN TOUCH
                    </div>
                    <div className="footer-upper-right-mail">
                        <AiOutlineMail /><a href="mailto:hello@mydms.pro">hello@mydms.pro</a>
                    </div>
                    <div className="footer-upper-right-phone">
                        <AiFillPhone /><a href="tel:0000000000">00000 00000</a>
                    </div>
                </div>
            </div>
            <div className="footer-container-lower-part">
                <span>myDMS.</span><span>pro</span><sup>TM</sup><span>&nbsp; @ 2023 ALL RIGHTS RESERVED.&nbsp; </span> <a href="https://appyard.co.in">DESIGNED BY APPYARD</a>
            </div>
        </div>
    )
}

export default Footer