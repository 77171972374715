import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import MainPage from "./Components/MainPage/MainPage";
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='*' exact={true} element={<MainPage />}/>
          <Route path='/' element={<MainPage />}/>
        </Routes>
        <Footer />
        {/* <div className="app-left-part">
          <img src={require("./images/bike.webp")} alt="overlap" />
        </div>
        <div className="app-right-part">
          <div className="app-right-logo">
            <img src={require("./images/logo.webp")} alt="logo" />
          </div>
          <div className="app-right-head">
            COMING SOON
          </div>
          <form className="app-right-content">
            <input type="email" placeholder="EMAIL  ADDRESS" />
            <button type="submit">NOTIFY ME</button>
          </form>
        </div>
        <div className="app-overlap">
          <img src={require("./images/")} alt="overlap" />
        </div> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
